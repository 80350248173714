.Navbar{
    display: flex;
    flex-direction: column;
    width: calc((100vw/5) - 20px);
    height: 100vh;
    padding-left: 20px;
    padding-top: 20px;
    position: fixed;
    box-shadow: 0.75rem 0.75rem 50px rgba(0, 0, 0, 0.15);
}

.navbar-wrap{
    display: flex;
    flex-direction: column;
    gap: 20px;
    transition: all 1s linear;
}

.navbar-select{
    display: flex;
    flex-direction: column;
    font-size: 1.8em;
    padding-left: 10px;
    padding-right: 10px;
    color:#757575;
    color: var(--portfolio-text-grey);
}

.cursor-hover{
    transition: color 0.4s ease-in-out;
}

.cursor-hover:hover{
    width: fit-content;
    cursor: pointer;
    color: black;
    color: var(--portfolio-text-black);
}

.navbar-subselect{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-self: center;
    width: 90%;
    font-size: 0.65em;
    gap: 20px;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 1s ease-in-out;
    
}

.navbar-subselect-m{
    max-height: 0 !important;
    opacity: 0 !important;
    visibility: hidden !important;
}
.scrollup-icon{
    display: none;
}

.navbar-subselect p{
    width: max-content;
    text-align: center;
    padding-top: 6px;
    color:#757575;
    color: var(--portfolio-text-grey);
    transition: color 0.4s ease-in-out, font-weight 0.4s ease-in-out;
    
}

.navbar-subselect p:hover{
    cursor: pointer;
    color: black;
    color: var(--portfolio-text-black);
}

.show-active{
    max-height: 100px;
    visibility: visible;
    opacity: 1;
    display: flex;
    /*visibility: visible;*/
}

.select-active{
    transition: color 0.4s ease-in-out, font-weight 0.4s ease-in-out;
    color: black;
    color: blue;
    background: rgb(102,218,194);
    background: radial-gradient(circle, rgba(102,218,194,1) 0%, rgba(53,145,255,1) 53%, rgba(70,171,233,1) 71%, rgba(119,20,255,1) 100%);
    background-clip: text;
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    background-color: white;
}

.subselect-active{
    transition: color 0.4s ease-in-out, font-weight 0.4s ease-in-out;
    color: black; 
    color: var(--portfolio-text-black) !important;
    font-weight: 600;
}
.mobile-wrap{
    width: inherit;
}
.navbar-top{
    width: inherit;
}
.navbar-img{
    width: inherit;
}

.navbar-img:hover{
    cursor: pointer;
}

.navbar-bottom{
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 30px;

}

.bottom-wrap{
    width: calc((100vw/5) - 40px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
}

.all-icons{
    display:flex; 
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.navbar-icon{
    transition: all 0.3s ease-in-out;
    scale: 1.2;
    color: #aaa;
    color: var(--portfolio-text-grey);
}

.navbar-icon:hover {
    scale: 1.4;
    color: rgba(53,145,255,1);
}

.icon-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    color: #757575;
    color: var(--portfolio-text-grey);
}

.icon-individual{
    scale: 1.2;
}

.CopyToClipboard{
    display: flex;
    align-items: center ;
    justify-content: center;
    gap: 8px;
  }
  
  .mail-copy:hover{
   cursor: pointer;
  }
  
  .mail-copy{
    transition: all .5s ease;
    font-size: 2em;
    display: flex;
    align-items: center ;
    justify-content: center;
  }

  .css-19h2mqp-MuiTooltip-tooltip{
    
    font-family: "Cormorant Upright", serif;
    font-size: 1em;
    background-color: #000;
    color: #fff;
  }

  .copyrignt-desktop{
    display: flex;
    align-items: center;
    justify-content: center;

  }
 

  /* Landscape phones and down */
@media (max-width: 480px) {
    .Navbar{
        position: relative;
        flex-direction: column;
        background-color: #fff;
        z-index: 100;
        width: inherit;
        max-height: fit-content;
        max-width: max-content;
        margin-left: 0px;
        margin-top: 0px;
        padding: 0;
        
        font-size: 0.5em;
        
    }
    .mobile-wrap{
        display: flex;
        flex-direction: column;
        width: inherit;
        overflow: hidden;


    }
    .subselects-mobile{
        z-index: 3;
        position: sticky !important; /* Make sure it's not overridden */
        top: 0;
        background-color: #fff;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-shadow: 0 8px 80px -40px lightblue;
    }

    .scrollup-icon{
        display: block;
        position: absolute;
        right: 40px;

        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 40px #ccc;
    }

  

    .navbar-subselect-m{
        font-size: 2.8em;
        color: #757575;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        max-width: 100vw;
    }

    .navbar-subselect{
        max-height: 0 !important;
        opacity: 0 !important;
        visibility: hidden !important;
    }

    .navbar-top{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        object-fit: fill;
        height: 150px;
        
    }

    .navbar-img{
        width: 80vw;
        
    }
    .navbar-wrap{
        align-self: center;
        font-size: 1.8em;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 40px;
        margin-right: 40px;
        gap: 10px;
        padding-top: 20px;
        
    }

    .navbar-select-m{
        font-size: 2.8em;
        padding: 0;
    }

    .navbar-select{
        align-self: center;
    }
    .navbar-select p{
        display: flex;
        text-align: center;
        align-self: center;
        max-width: fit-content !important;
    }
    .cursor-hover {
        width: fit-content;
    }

    .show-active-m{
        max-height: 100px !important;
        opacity: 1 !important;
        visibility: visible !important;
    }
    
    
    .select-active{
        transition: color 0.4s ease-in-out, font-weight 0.4s ease-in-out;
        color: black;
        color: blue;
        background: rgb(102,218,194);
        background: radial-gradient(circle, rgba(102,218,194,1) 0%, rgba(53,145,255,1) 53%, rgba(70,171,233,1) 71%, rgba(119,20,255,1) 100%);
        background-clip: text;
        -webkit-background-clip: text; 
        -webkit-text-fill-color: transparent;
        font-weight: 600;
        background-color: white;
    }
    
    .subselect-active{
        transition: color 0.4s ease-in-out, font-weight 0.4s ease-in-out;
        color: black; 
        color: var(--portfolio-text-black) !important;
        font-weight: 600;
    }

    .navbar-icon{
        scale: 0.8;
    }
    .all-icons{
        display:flex; 
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .navbar-bottom{
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        bottom: 0;
        font-size: 1.8em;
         
    }


    .bottom-wrap{
        width: 100%;
        gap: 5px;
    }

    .all-icons{
        display: none;
    }
    .copyrignt-desktop{
        display: none;
    
      }
}

/* Landscape phone to portrait tablet */
@media (min-width: 480px) and (max-width: 767px) {  
    .Navbar{
        position: relative;
        flex-direction: column;
        background-color: #fff;
        z-index: 100;
        width: 100vw;
        max-height: fit-content;
        margin-left: 0px;
        margin-top: 0px;
        padding: 0;

        
        font-size: 0.5em;
        
    }
    .mobile-wrap{
        display: flex;
        flex-direction: column;
        width: inherit;
        overflow: hidden;


    }
    .subselects-mobile{
        z-index: 3;
        position: sticky !important; /* Make sure it's not overridden */
        top: 0;
        background-color: #fff;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-shadow: 0 8px 80px -40px lightblue;
    }

    .scrollup-icon{
        display: block;
        position: absolute;
        right: 40px;

        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 40px #ccc;
    }

  

    .navbar-subselect-m{
        font-size: 2.8em;
        color: #757575;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        max-width: 100vw;
    }

    .navbar-subselect{
        max-height: 0 !important;
        opacity: 0 !important;
        visibility: hidden !important;
    }

    .navbar-top{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        object-fit: fill;
        height: 150px;
        
    }

    .navbar-img{
        width: 50vw;
        
    }
    .navbar-wrap{
        align-self: center;
        font-size: 1.8em;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 40px;
        margin-right: 40px;
        gap: 10px;
        padding-top: 20px;
        
    }

    .navbar-select-m{
        font-size: 2.8em;
        padding: 0;
    }

    .navbar-select{
        align-self: center;
    }
    .navbar-select p{
        display: flex;
        text-align: center;
        align-self: center;
        max-width: fit-content !important;
    }
    .cursor-hover {
        width: fit-content;
    }

    .show-active-m{
        max-height: 100px !important;
        opacity: 1 !important;
        visibility: visible !important;
    }
    
    
    .select-active{
        transition: color 0.4s ease-in-out, font-weight 0.4s ease-in-out;
        color: black;
        color: blue;
        background: rgb(102,218,194);
        background: radial-gradient(circle, rgba(102,218,194,1) 0%, rgba(53,145,255,1) 53%, rgba(70,171,233,1) 71%, rgba(119,20,255,1) 100%);
        background-clip: text;
        -webkit-background-clip: text; 
        -webkit-text-fill-color: transparent;
        font-weight: 600;
        background-color: white;
    }
    
    .subselect-active{
        transition: color 0.4s ease-in-out, font-weight 0.4s ease-in-out;
        color: black; 
        color: var(--portfolio-text-black) !important;
        font-weight: 600;
    }

    .navbar-icon{
        scale: 0.8;
    }
    .all-icons{
        display:flex; 
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .navbar-bottom{
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        bottom: 0;
        font-size: 1.8em;
         
    }


    .bottom-wrap{
        width: 100%;
        gap: 5px;
    }

    .all-icons{
        display: none;
    }
    .copyrignt-desktop{
        display: none;
    
      }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 1024px) {  
    .Navbar{
        position: relative;
        flex-direction: column;
        background-color: #fff;
        z-index: 100;
        width: 100vw;
        max-height: fit-content;
        margin-left: 0px;
        margin-top: 0px;
        padding: 0;
        
        font-size: 0.5em;
        
    }
    .mobile-wrap{
        display: flex;
        flex-direction: column;
        width: inherit;
        overflow: hidden;


    }
    .subselects-mobile{
        z-index: 3;
        position: sticky !important; /* Make sure it's not overridden */
        top: 0;
        background-color: #fff;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-shadow: 0 8px 80px -40px lightblue;
    }

    .scrollup-icon{
        display: block;
        position: absolute;
        right: 40px;

        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 40px #ccc;
    }

  

    .navbar-subselect-m{
        font-size: 2.8em;
        color: #757575;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        max-width: 100vw;
    }

    .navbar-subselect{
        max-height: 0 !important;
        opacity: 0 !important;
        visibility: hidden !important;
    }

    .navbar-top{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        object-fit: fill;
        height: 150px;
        
    }

    .navbar-img{
        width: 35vw;
        
    }
    .navbar-wrap{
        align-self: center;
        font-size: 1.8em;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 40px;
        margin-right: 40px;
        gap: 10px;
        padding-top: 20px;
        
    }

    .navbar-select-m{
        font-size: 2.8em;
        padding: 0;
    }

    .navbar-select{
        align-self: center;
    }
    .navbar-select p{
        display: flex;
        text-align: center;
        align-self: center;
        max-width: fit-content !important;
    }
    .cursor-hover {
        width: fit-content;
    }

    .show-active-m{
        max-height: 100px !important;
        opacity: 1 !important;
        visibility: visible !important;
    }
    
    
    .select-active{
        transition: color 0.4s ease-in-out, font-weight 0.4s ease-in-out;
        color: black;
        color: blue;
        background: rgb(102,218,194);
        background: radial-gradient(circle, rgba(102,218,194,1) 0%, rgba(53,145,255,1) 53%, rgba(70,171,233,1) 71%, rgba(119,20,255,1) 100%);
        background-clip: text;
        -webkit-background-clip: text; 
        -webkit-text-fill-color: transparent;
        font-weight: 600;
        background-color: white;
    }
    
    .subselect-active{
        transition: color 0.4s ease-in-out, font-weight 0.4s ease-in-out;
        color: black; 
        color: var(--portfolio-text-black) !important;
        font-weight: 600;
    }

    .navbar-icon{
        scale: 0.8;
    }
    .all-icons{
        display:flex; 
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .navbar-bottom{
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        bottom: 0;
        font-size: 1.8em;
         
    }


    .bottom-wrap{
        width: 100%;
        gap: 5px;
    }

    .all-icons{
        display: none;
    }
    .copyrignt-desktop{
        display: none;
    
      }
}

/* Large desktop */
@media (min-width: 1024px) and (max-width: 1680px) {  
    .navbar-subselect{
        justify-content: flex-start;
    }
}