* {
  margin: 0;
  padding: 0;

  overscroll-behavior: auto;
  font-family: century-gothic, sans-serif;
  font-family: "Cormorant Upright", serif;

  font-family: "Bellefair", serif;
  font-weight: 400 !important;

  /*

  font-family: "Bellefair", serif;
  font-weight: 400 !important;
  

  font-family: "Timeless-Normal V1";
  font-family: "Forum", serif;
  
  font-family: "Kurale", serif;
  font-family: "Oranienbaum", serif;
  
  /*font-family: "Montserrat", sans-serif;
  */
  
  scroll-behavior: smooth;
}

:root {
  --section-padding: 358px;
  --portfolio-text-black: #000;
  --portfolio-text-grey-darker: #757575;
  --portfolio-text-grey: #959595;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #131313;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

button{
  all: unset;

  border: 2.5px solid #0491C1;
  background: linear-gradient(60deg, #086082, #00c2ff);
  color: #fff;
  
  background-position: middle;
  background-size: 100% 100%;
  transition: all .5s ease;
}

button:hover {
  cursor: pointer;
  background-size: 2500% 100%;
  background-position: right bottom;
  scale: 1.01;
}

button a {
  all: unset;
}


a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}






@media only screen and (max-width: 600px) {

  button a{
    font-size: 6vw;
  }

  .headline {
    font-size: 6vw;

  }
}

@media only screen and (max-width: 1366px) {

  button a{
    font-size: 8vw;
  }

  .headline {
    font-size: 7vw;

  }
}


