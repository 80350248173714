.Video {
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}



.video-heading {
    font-size: 1.8rem;
    color: #757575;
}


.video-responsive {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
}

.video-responsive iframe{
    width: 95%;
    height: 75vh;
}
    
/* Landscape phones and down */
@media (max-width: 480px) {
    .Video{
        padding-top: 60px;
        padding-bottom: 30px;
        justify-content: flex-start;
    }
    .video-heading {
        font-size: 1.6rem;
        text-align: center;
        padding-bottom: 30px;
    }
    .video-responsive iframe{
        width: 100%;
        height: 25vh;
    }
  }
  
  /* Landscape phone to portrait tablet */
  @media (max-width: 767px) {  }
  
  /* Portrait tablet to landscape and desktop */
  @media (min-width: 768px) and (max-width: 979px) {  }
  
  /* Large desktop */
  @media (min-width: 1200px) {  }